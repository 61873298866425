import React, { Fragment } from 'react';
import { Layout } from 'antd';

const CustomContent = ({ContentBody}) => {
  const { Content } = Layout;

  return (
    <Fragment>
      <Content className='content-wrapper'>
        <div className='content-body'>
          {ContentBody}
        </div>
      </Content>
    </Fragment>
  );
};

export default CustomContent;