import { Result } from 'antd';

const PagePC = ({
  history,
}) => {
  return (
    <Result
      className='pt-70 pb-70'
      status='warning'
      title='403'
      subTitle='모바일 전용웹 입니다. 모바일로 접속 해주세요.'
    />
  );
};

export default PagePC;