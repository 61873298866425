import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { routesList } from './index';
// import Page404 from '../components/views/Page404';
import PagePC from '../components/views/PagePC';

const Routes = () => {
  const pcRoute = () => {
    return (
      <Route
        render={props => (
          <PagePC {...props} />
        )}
      />
    );
  };

  // const page404Route = () => {
  //   return (
  //     <Route
  //       render={props => (
  //         <Page404 {...props} />
  //       )}
  //     />
  //   );
  // };

  const isMobile = () => {
    const pc = 'win16|win32|win64|mac|macintel';
    if (navigator.platform) {
      if (pc.indexOf(navigator.platform.toLowerCase()) < 0) return true;
      else return false;
    }
    else return true;
  };

  return (
    <Router>
      <Switch>
        {isMobile() ? (
          <Fragment>
            {routesList.map(({ path, component: Component }, index) => (
              <Route
                key={index}
                path={path}
                exact
                render={props => (
                  <Component {...props} />
                )}
              />
            ))}
            {/* {page404Route()} */}
          </Fragment>
        ) : (
          <Fragment>
            {pcRoute()}
          </Fragment>
        )}
      </Switch>
    </Router>
  )
};

export default Routes;
