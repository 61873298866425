import * as types from '../constants';

const initialState = {
  bookingListItems: [],
};

const BookingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BOOKING_LIST:
      return {
        ...state,
        bookingListItems: action.payload,
      };
    default:
      return state;
  }
};

export default BookingListReducer;